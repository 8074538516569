import Google from "../imgs/icons/google-plus-svgrepo-com.svg"
import OLX from "../imgs/icons/olx-seeklogo.svg"
import Facebook from "../imgs/icons/facebook.svg"
import {Link} from "react-router-dom"

export default function SocialMedia(){
    return(
        <div className="flex flex-row gap-2 p-1 grow justify-end">
            <Link target="_blank" to="https://www.google.com/maps/place/StarGold+Amanet-Electronice/@44.4465953,26.1217468,17z/data=!3m1!4b1!4m6!3m5!1s0x40b1f8cc81a55555:0x2290db71af516ccc!8m2!3d44.4465953!4d26.1217468!16s%2Fg%2F11gh2qw_6q?entry=ttu" className="socialIcon w-8 h-8 self-center transition-all hover:scale-125"><img className="" src={Google}/></Link>
            <Link target="_blank" to="https://www.facebook.com/StarGoldAmanetElectroniceMosilor241/" className="socialIcon w-9 h-9 self-center transition-all hover:scale-125"><img className="" src={Facebook}/></Link>
            <Link target="_blank" to="https://www.olx.ro/oferte/user/2ccUT/" className="socialIcon w-8 h-8 self-center transition-all hover:scale-125"><img className="" src={OLX}/></Link>
        </div>
    )
}