import Header from "../imgs/header1.webp"
import SocialMedia from "./socialMedia"
import Toolbar from "./toolbar"
import {Body} from "./mainBody.jsx"
import {Footer} from "./footer"

export default function FirstPage(){
    return(
    <>
    <Toolbar />
    <Body />
    <Footer />
    </>
)}