import Logo from "../imgs/LogoTransparent.png"
import {Link} from "react-router-dom"
import SocialMedia from "./socialMedia.jsx"


export default function Toolbar(){return(
    <div className="flex justify-center mt-6 w-full">
        <div className="toolbar flex w-screen gap-3 text-white pr-10 bg-neutral-700">
            <h1 className="text-2xl p-6">Stargold amanet</h1>
            <SocialMedia />
        </div> {/* Toolbar */}
    </div>
)
}