import Background from "../imgs/header1.webp"
import {Link,Links} from "react-router-dom"
import Contact from "./contact"

export function Body(){
    return(
    <>
    <div className="flex flex-wrap justify-center">
        <div className="mainHeader flex justify-center p-6">
            <div className="hidden lg:flex w-1/2 flex flex-wrap justify-center content-center">
                <h1 className="text-white text-center">Cumparam<br/>vindem<br/>amanetam</h1>
            </div>
            <img className="w-full lg:w-1/2" src={Background}></img>
        </div>
        <div className="mainBody bg-neutral-700 p-6">
            <h2 className="text-white text-4xl text-center mb-8">Ce servicii oferim</h2>
            <div className="contactCards gap-2 flex flex-wrap mb-8 justify-center">
        <div className="contactCard bg-neutral-800 text-white px-8 w-full lg:w-3/4 py-6 text-lg">
            <h3 className="text-2xl text-white text-gold mb-3">Vindem</h3>
        În căutarea unor oferte incredibile? La magazinul nostru de amanet, nu numai că oferim prețuri
         avantajoase pentru obiectele Dvs, dar avem și o selecție minuțios aleasă de produse de calitate
          la prețuri uimitoare. Indiferent dacă căutați electronice, unelte sau obiecte de lux, avem ceva
           pentru fiecare. Vizitați-ne și bucurați-vă de emoția găsirii unor oferte incredibile!
        </div>
        <div className="contactCard bg-neutral-800 text-white px-8 w-full lg:w-3/4 py-6 text-lg">
            <h3 className="text-2xl text-white text-gold mb-3">Cumparam</h3>
        Bani pe loc? Da! aduceti produsele dvs. la magazinul nostru de amanet! Oferim evaluari
         corecte si plati instant, ajutandu-va sa obtineti valoarea pe care o meritati fara probleme. Sa transformam
          astazi obiectele dvs. in bani!"
        </div>
        <div className="contactCard bg-neutral-800 text-white px-8 w-full lg:w-3/4 py-6 text-lg">
            <h3 className="text-2xl text-white text-gold mb-3">Aur</h3>
        Aveți aur de vânzare? La magazinul nostru de amanet, suntem interesați să achiziționăm aur de 
        calitate la prețuri competitive. Cu evaluări corecte și plăți instantanee, vă oferim o soluție 
        rapidă și sigură pentru a obține bani pentru aurul dvs. Nu mai stați pe gânduri - aduceți aurul 
        dvs. la noi și beneficiați de ofertele noastre avantajoase astăzi
        </div>
    </div>
            <Contact />
        </div>
    </div>
    </>
    )
}