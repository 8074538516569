export default function Contact(){
    return(
    <>
    <h2 className="text-4xl text-white text-center mb-6">Contacteaza-ne!</h2>
    <div className="contactCards gap-2 justify-center flex flex-wrap mb-8">
        <div className="contactCard grow bg-neutral-800 text-white px-6 py-14 text-xl text-center grow">
        Calea Moșilor 241, București 020897
        </div>
        <div className="contactCard grow bg-neutral-800 text-white px-6 py-14 text-xl text-center grow">
        0751 545 839
        </div>
        <div className="contactCard grow bg-neutral-800 text-white px-6 py-14 text-xl text-center grow">
        stargoldmosilor241@gmail.com
        </div>
    </div>
    <div className="location">
        <iframe className="w-full h-full" style={{filter: "invert(90%)"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.3450567357627!2d26.1217468!3d44.4465953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1f8cc81a55555%3A0x2290db71af516ccc!2sStarGold%20Amanet-Electronice!5e0!3m2!1sro!2sro!4v1708939084358!5m2!1sro!2sro" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    </>
    )
}