import ANPC from "../imgs/icons/SAL.png"
import SOL from "../imgs/icons/SOL.png"

export function Footer(){
    return(
        <>
        <div className="footerBackground flex justify-center bg-neutral-700 h-80 mt-20">
            <div className="footer flex flex-wrap bg-neutral-700 h-full self-end p-6">
                <div className="text-white flex flex-wrap text-2xl w-1/3 justify-center content-center">
                Stargold Amanet<br/>
                Calea Moșilor 241<br/>
                București Sector 2<br/>
                020897<br/>
                </div>
                <div className="flex flex-wrap w-1/3 content-center justify-center text-white text-2xl text-center">
                <a className="p-6 w-full flex justify-center" href="https://anpc.ro/ce-este-sal/"><img src={ANPC}/></a>
                <a className="p-6 w-full flex justify-center" href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"><img src={SOL}/></a>
                Star Gold Solution Wide SRL
                </div>
                <div className="text-white flex flex-wrap text-xl w-1/3 justify-center content-center">
                    <a href="" target="_blank" className="p-6 hover:underline">Google</a>
                    <a href="" target="_blank" className="p-6 hover:underline">Facebook</a>
                    <a href="" target="_blank" className="p-6 hover:underline">OLX</a>
                </div>
            </div>
        </div>
        </>
    )
}