import './index.css';
import Toolbar from "./myComponents/toolbar.jsx";
import FirstPage from "./myComponents/mainPage.jsx";
import {Route,Routes} from "react-router-dom"

function App() {
  return (
    <>
    <Routes>
      <Route index element={<FirstPage />}/>
    </Routes>
    </>
  );
}

export default App;
